import React, { useState, useEffect } from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';
import Button from '../../../components/Button/Button';
import { updateAdherent } from '../../../services/adherentServices';
import { toast } from 'react-toastify';
import { getSettings } from '../../../services/settingsServices';

const EditAdherentAdminModal = ({ isOpen, onClose, refreshList, adherent }) => {
  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    email_secondaire: '',
    tel: '',
    tel_secondaire: '',
    adresse: '',
    cp: '',
    commune: '',
    genre: '',
    statut_inscription: '',
    date_naissance: '',
    photo_url: '',
    licence_number: '',
    poids: '',
    saison_active: '',
    notes_adherent: '',
    autorisation_photo: false,
  });

  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSettings();
        if (settingsData.length > 0) {
          const fetchedSettings = settingsData[0];
          const previousSeason = getPreviousSeason(fetchedSettings.saison_active);
          const nextSeason = getNextSeason(fetchedSettings.saison_active);
          setSeasons([previousSeason, fetchedSettings.saison_active, nextSeason]);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des paramètres:", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (adherent) {
      setFormData({
        nom: adherent.nom || '',
        prenom: adherent.prenom || '',
        email: adherent.email || '',
        email_secondaire: adherent.email_secondaire || '',
        tel: adherent.tel || '',
        tel_secondaire: adherent.tel_secondaire || '',
        adresse: adherent.adresse || '',
        cp: adherent.cp || '',
        commune: adherent.commune || '',
        genre: adherent.genre || '',
        statut_inscription: adherent.statut_inscription || '',
        date_naissance: adherent.date_naissance ? new Date(adherent.date_naissance).toISOString().slice(0, 10) : '',
        photo_url: adherent.photo_url || '',
        licence_number: adherent.licence_number || '',
        poids: adherent.poids || '',
        saison_active: adherent.saison_active || '',
        notes_adherent: adherent.notes_adherent || '',
        autorisation_photo: adherent.autorisation_photo || false,
      });
    }
  }, [adherent]);

  const getPreviousSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear - 1}-${endYear - 1}`;
  };

  const getNextSeason = (currentSeason) => {
    const [startYear, endYear] = currentSeason.split("-").map(Number);
    return `${startYear + 1}-${endYear + 1}`;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('FormData envoyé:', formData);
    try {
      await updateAdherent(adherent.id, formData);
      refreshList();
      onClose();
      toast.success('Adhérent mis à jour avec succès');
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'adhérent", error);
      toast.error("Erreur lors de la mise à jour de l'adhérent");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="modal-box bg-white p-4 rounded-lg shadow-lg">
        <h3 className="text-xl font-bold uppercase p-3 mb-4 text-center text-white bg-primary rounded-md shadow">
          Modifier un Adhérent
        </h3>
        <form onSubmit={handleSubmit}>
          <input type="text" name="nom" placeholder="Nom" value={formData.nom} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="text" name="prenom" placeholder="Prénom" value={formData.prenom} onChange={handleChange} className="input input-bordered w-full mb-3" required />
          <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="email" name="email_secondaire" placeholder="Email secondaire" value={formData.email_secondaire} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="tel" placeholder="Téléphone" value={formData.tel} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="tel_secondaire" placeholder="Téléphone secondaire" value={formData.tel_secondaire} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="adresse" placeholder="Adresse" value={formData.adresse} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="cp" placeholder="Code Postal" value={formData.cp} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="commune" placeholder="Commune" value={formData.commune} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <select name="genre" value={formData.genre} onChange={handleChange} className="select select-bordered w-full mb-3">
            <option value="">Sélectionnez un genre</option>
            <option value="Masculin">Masculin</option>
            <option value="Féminin">Féminin</option>
          </select>
          <input type="date" name="date_naissance" value={formData.date_naissance} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="photo_url" placeholder="URL de la photo" value={formData.photo_url} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="text" name="licence_number" placeholder="Numéro de licence" value={formData.licence_number} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <input type="number" name="poids" placeholder="Poids" value={formData.poids} onChange={handleChange} className="input input-bordered w-full mb-3" />
          <select name="statut_inscription" value={formData.statut_inscription} onChange={handleChange} className="select select-bordered w-full mb-3">
            <option value="">Sélectionnez un statut d'inscription</option>
            <option value="Licencié">Licencié</option>
            <option value="Inscrit">Inscrit</option>
            <option value="Cours d'essai">Cours d'essai</option>
            <option value="Licencié - Arrêt">Licencié - Arrêt</option>
            <option value="Non Licencié - Arrêt">Non Licencié - Arrêt</option>
            <option value="Licencié Hors Club">Licencié Hors Club</option>
            <option value="Ancien Licencié">Ancien Licencié</option>
          </select>
          <select name="saison_active" value={formData.saison_active} onChange={handleChange} className="select select-bordered w-full mb-3">
            {seasons.map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>
          <textarea name="notes_adherent" placeholder="Notes" value={formData.notes_adherent} onChange={handleChange} className="textarea textarea-bordered w-full mb-3" />
          <label className="flex items-center space-x-2 mb-4">
            <input type="checkbox" name="autorisation_photo" checked={formData.autorisation_photo} onChange={handleChange} />
            <span>Autorisation photo</span>
          </label>
          <div className="flex justify-center space-x-2 mt-4">
            <Button text="Enregistrer" icon={FaSave} type="submit" className="btn-primary gap-2" />
            <Button text="Annuler" icon={FaTimes} onClick={onClose} type="button" className="btn-ghost gap-2" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAdherentAdminModal;
