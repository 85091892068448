import React from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "../../Home/ScrollIndicator";

const WadoRyu = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        DISCIPLINE
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-center text-gray-800 dark:text-white mb-4 font-montserrat uppercase">
        Wado-Ryu
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="mt-8 mx-auto p-4 max-w-[1280px] space-y-8">
        {/* Section Introduction */}
        <div className="bg-blue-50 rounded-lg shadow-lg p-6 flex flex-col md:flex-row items-center transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <div className="md:w-2/3 p-4 text-gray-700">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">LE WADO RYU</h3>
          <p className="mb-4 text-gray-700">
            Le wado-ryu "voie de la paix" : ("RYU" : école, "DO" : voie, "WA" : paix) est une des quatre principales écoles de karaté moderne. C'est un style japonais de karaté créé en 1938 par <b>Hironori OTSUKA</b>.
          </p>
          <p className="text-gray-700">
            Les principes du wado-ryu sont représentés par la colombe "symbole de la paix" et le poing "symbole de la force".
          </p>
          </div>
          <div className="md:w-1/3 p-4">
            <img
              src="https://res.cloudinary.com/dy5kblr32/image/upload/v1726999680/fake/wado-ryu02_cpdstw.webp"
              alt="symbole wado-ryu"
              className="rounded-lg shadow-md transition-transform duration-300 hover:scale-105"
            />
          </div>
        </div>

        {/* Section Historique */}
        <div className="bg-blue-50 rounded-lg shadow-lg p-6 flex flex-col md:flex-row items-center transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <div className="md:w-1/3 p-4">
            <img
              src="https://res.cloudinary.com/dy5kblr32/image/upload/v1726998304/fake/Maitre-Hironori-Otsuka_yao0bm.webp"
              alt="Maître Hironori Otsuka"
              className="rounded-lg shadow-md transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="md:w-2/3 p-4 text-gray-700">
            <p className="mb-4">
              <b>Maître Hironori Otsuka</b>, fondateur du karaté wado-ryu, est né à Chimonate le 1er juin 1892. À l'âge de 6 ans, le 1er avril 1897, Maître Otsuka entra à l'école de Shizaburo Nakayama.
            </p>
            <p className="mb-4">
              À 29 ans le 1er juin 1920, après avoir accompli toutes les leçons, il avait reçu le plus haut degré de l'école et atteint la position du maître. Cela faisait de lui le 4ème maître du Shinto Yoshin Ryu.
            </p>
            <p className="mb-4">
              En juillet 1922, Maître Otsuka commença à étudier l'Okinawan Tode (karaté). Pendant ses études, il développe alors un style en fusionnant ses connaissances en karaté (shotokan) et en jiu-jutsu visant à éviter les coups de l'adversaire plutôt qu'à les bloquer suite à une sévère défaite en karaté shotokan.
            </p>
            <p className="mb-4">
              En mai 1934 à 43 ans, il fonda le wado-ryu karaté jutsu et le wadoryu ju-jutsu kempo en faisant fusionner les caractéristiques de l'Okinawan karaté avec le jiu-jitsu traditionnel tout en y ajoutant des éléments de sa propre création.
            </p>
            <p className="mb-4">
              L'idéal du wado-ryu est "paix et harmonie". Maître Otsuka nous a enseigné que la voie des arts martiaux n'est pas qu'une simple technique de combat mais la voie de la paix et de l'harmonie ce qui est plus difficile à atteindre que la victoire par la violence.
            </p>
            <p>
              Le 20 novembre 1981, il céda la position de Grand Maître à Jiro Otsuka, son fils aîné. Hironori Otsuka décéda le 29 janvier 1982 à l'âge de 90 ans.
            </p>
          </div>
        </div>

        {/* Section sur les Principes du Wado-Ryu */}
        <div className="bg-blue-100 rounded-lg shadow-lg p-6 transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">LE WADO RYU</h3>
          <p className="mb-4 text-gray-700">
            Le wado-ryu se caractérise par des positions plus hautes que dans les autres méthodes et repose sur la technique de l'esquive puis de la contre-attaque à l'opposé du seul usage de la force et de l'attaque.
          </p>
          <p className="mb-4 text-gray-700">
            Certaines techniques de poings du karaté wado-ryu se trouvent uniquement dans cette méthode, tel que Jun Tsuki No Tsukikomi, Gyaku Tsuki No Tsukikomi, Tobi Komi Tsuki, Tobi Komi Nagashi Tsuki...
          </p>
          <p className="mb-4 text-gray-700">
            Il est important de pratiquer avec le "SHIN" (cœur, esprit), le "GI" (la technique) et le "TAI" (le corps).
          </p>
          <p className="mb-4 text-gray-700">
            Cette doctrine illustre parfaitement les 3 principes de base du karaté wado-ryu qui sont :
          </p>
          <ul className="list-disc ml-5 mb-4 space-y-2 text-gray-700">
            <li><strong>Noru</strong> : rentre, contrer, pénétrer.</li>
            <li><strong>Nagasu</strong> : aspirer, accompagner, laisser passer, enrouler.</li>
            <li><strong>Inasu</strong> : combinaison de Noru et Nagasu résumé par "action dans l'esquive", bloquer et contrer en même temps.</li>
          </ul>
          <p className="text-gray-700">
            Le style wado-ryu se distingue des autres écoles de karaté par l'étude systématique des différents formes de combats conventionnels (Yokusolu kumité) parallèlement à celles des katas. Se travaillant à deux en respectant un cérémonial bien précis, en voici la liste :
          </p>
          <img
            src="https://res.cloudinary.com/dy5kblr32/image/upload/v1726998454/fake/wadokanji_desql6.jpg"
            alt="Kanji Wado-Ryu"
            className="rounded-lg shadow-md mx-auto transition-transform duration-300 hover:scale-105"
          />
        </div>

        {/* Section sur les Formes de Combat */}
        <div className="bg-blue-50 rounded-lg shadow-lg p-6 transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">Formes de Combat</h3>
          <ul className="list-disc ml-5 space-y-2 text-gray-700">
            <li><strong>Les Itoris</strong> : défenses à genou contre un adversaire également à genou ou debout. Ils sont au nombre de cinq.</li>
            <li><strong>Les Tantos toris</strong> : cinq attaques au couteau enchaînées avec un travail important de clés et projection.</li>
            <li><strong>Les Kihons kumités</strong> : au nombre de 10, ces attaques variées incluent des esquives propres à la méthode, des clés et des projections. Ils représentent des combats arrangés, axés sur une défense instinctive contre des attaques très rapides.</li>
            <li><strong>Les Ohyo kumités</strong> : techniques se travaillant par deux, composées d'enchaînements de plusieurs mouvements.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WadoRyu;
