import React from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "../../Home/ScrollIndicator";

const ArbitrageKarate = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const images = [
    {
      src: "/img/arbitrage/reglement_competition_karate.png",
      alt: "Règlement des Compétitions 2024-2025",
      link: "https://www.ffkarate.fr/wp-content/uploads/2024/09/Reglement-competitions-2024-2025-version-au-25.08_couv.pdf",
    },
    {
      src: "/img/arbitrage/reglement_arbitrage_karate.png",
      alt: "Règlement d'Arbitrage 2024-2025",
      link: "https://www.ffkarate.fr/wp-content/uploads/2024/09/Reglement-arbitrage_2024-2025_28.08_couv.pdf",
    },
    {
      src: "/img/arbitrage/reglement_coaching_karate.png",
      alt: "Règlement de Coaching 2024-2025",
      link: "https://www.ffkarate.fr/wp-content/uploads/2024/09/Reglement-coaching-2024-2025_09.11_couv_02.pdf",
    },
  ];

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Arbitrage Karaté
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8 mx-auto p-4 max-w-[1280px]">
        {images.map((image, index) => (
          <a
            key={index}
            href={image.link}
            target="_blank"
            rel="noopener noreferrer"
            className="group relative overflow-hidden rounded-lg shadow-lg transform transition duration-300 hover:scale-105"
          >
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-auto object-cover group-hover:opacity-75 transition duration-300"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition duration-300">
              <p className="text-white text-lg font-semibold">{image.alt}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ArbitrageKarate;
